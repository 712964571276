<script setup lang="ts">
import { showConfirmDialog } from 'vant'
import utils from './utils.ts'
import { jumpToRefidUrl } from '~/utils/refid/index.js'

const refPopularCity = ref(null)
const { query } = useRoute()

const searchTimer = null
// 国际的校正三字码
const cityCodeHash = {
  PEK: 'BJS', // 北京
  NAY: 'BJS', // 北京南苑
  XIY: 'SIA', // 西安
  PVG: 'SHA', // 上海浦东
}
// const cityNameHash = {
//   上海虹桥: '上海',
//   上海浦东: '上海',
//   北京首都: '北京',
//   北京南苑: '北京',
// }

// Economy:经济舱  Premium economy:超级经济舱  Bussiness:公务舱  First:头等舱
const seats = [{
  value: 'Y',
  txt: 'f_iflight_economy',
},
{
  value: 'S',
  txt: 'f_iflight_premium',
},
{
  value: 'C',
  txt: 'f_iflight_bussiness',
},
{
  value: 'F',
  txt: 'f_iflight_first',
},
]

// data数据
const state = reactive({
  flightStartCity: '',
  flightEndCity: '',

  flightGoDate: '', // 国内机票出发日期
  flightBackDate: '', // 国内机票返回日期
  flightLineType: 0, // 航线类型 1国内 0国际

  fromInfo: '', // 展示出发数据
  toInfo: '', // 展示到达数据
  isFromLine: false,
  isToLine: false,
  QerrorMsg: '', // 城市提醒
  DerrorMsg: '', // 日期提醒
  seatPassenger: '',

  flightAdult: 1, // 机票成人票数
  flightChild: 0, // 机票儿童票数

  flightSeatList: seats, // 座位列表
  flightSeatIndex: 0, // 默认座位索引
  flightSeat: seats[0], // 座位

  typeIndex: 1,

  isMask: false, // 蒙层
  festivals: {}, // 节日数据

  b_iflight_from: utils.tc('b_iflight_from'),
  b_iflight_to: utils.tc('b_iflight_to'),
  b_iflight_departure: utils.tc('b_iflight_departure'),
  b_iflight_return: utils.tc('b_iflight_return'),
  b_iflight_class_passenger: utils.tc('b_iflight_class_passenger'),
})

let calObj1: any = {}

// 事件
const methods = {
  cityInputTimer: null as any,
  async init() {
    const typeIndex = useCookie<number>('hopegoo_index_type').value

    state.typeIndex = typeIndex === undefined ? 1 : typeIndex

    state.b_iflight_from = utils.tc('b_iflight_from')
    state.b_iflight_to = utils.tc('b_iflight_to')
    state.b_iflight_departure = utils.tc('b_iflight_departure')
    state.b_iflight_return = utils.tc('b_iflight_return')
    state.b_iflight_class_passenger = utils.tc('b_iflight_class_passenger')

    try {
      const tid = setTimeout(() => {
        clearTimeout(tid)
        if (process.client) {
          this.getHoliday() // 请求节日
        }
      }, 0)
      // 日历组件初始化
      calObj1 = new $.Calendar({
        skin: 'white',
        panelWidth: 780,
        wrapperWidth: 368,
        monthNum: 2,
        locale: utils.curLang,
        pricePlaceholder: utils.tc('f_calendar_price'),
      })

      // 全局触发关闭
      document.body.addEventListener('click', () => {
        this.allOnBlur()
      })

      // 阻止元素冒泡
      utils.stopClick([
        '.btn-change',
        '.from-box .input-box',
        '.to-box .input-box',
        '.godate-box',
        '.backdate-box',
        '.popular-city-wrapper',
        '.search-city-wrapper',
        '.seat-passenger-wrapper',
        '.query-passenger-box',
        '.tab_ul .tab-li',
        '.travel_type .type',
        '.btn-search',
      ])
    }
    catch (e) { }

    // if (utils.curLang == 'en-us') {
    //   state.flightStartCity = utils.storage.flightStartCity
    //     ||
    //     {
    //     'CityCode': 'HKG',
    //     'CityName': '香港',
    //     'PortCode': 'HKG',
    //     'PortName': '香港国际机场',
    //     'CityNameX': 'Hong Kong',
    //     'PortNameEn': 'Hong Kong International Airport',
    //     'type': 0
    //   }
    //   ;
    //   state.flightEndCity = utils.storage.flightEndCity
    //     ||
    //     {
    //     'CityCode': 'BJS',
    //     'CityName': '北京',
    //     'PortCode': 'BJS',
    //     'PortName': '北京首都',
    //     'CityNameX': 'Beijing',
    //     'PortNameEn': 'Beijing Capital International Airport',
    //     'type': 1
    //   }
    //   ;
    // }
    // if (utils.curLang == 'zh-hk') {
    //   state.flightStartCity = utils.storage.flightStartCity
    //     ||
    //     {
    //     'CityCode': 'HKG',
    //     'CityName': '香港',
    //     'PortCode': 'HKG',
    //     'PortName': '香港國際機場',
    //     'CityNameX': '香港',
    //     'PortNameEn': 'Hong Kong International Airport',
    //     'type': 0
    //   }
    //   ;
    //   state.flightEndCity = utils.storage.flightEndCity
    //     ||
    //     {
    //     'CityCode': 'BJS',
    //     'CityName': '北京',
    //     'PortCode': 'BJS',
    //     'PortName': '北京首都',
    //     'CityNameX': '北京',
    //     'PortNameEn': 'Beijing Capital International Airport',
    //     'type': 1
    //   }
    //   ;
    // }
    // if (utils.curLang == 'zh-tw') {
    //   state.flightStartCity = utils.storage.flightStartCity
    //     ||
    //     {
    //     'CityCode': 'TPE',
    //     'CityName': '台北',
    //     'PortCode': 'TPE',
    //     'PortName': '台北桃園機場',
    //     'CityNameX': '台北',
    //     'PortNameEn': 'Taiwan Taoyuan International Airport',
    //     'type': 0
    //   }
    //   ;
    //   state.flightEndCity = utils.storage.flightEndCity
    //   ||
    //     {
    //     'CityCode': 'SHA',
    //     'CityName': '上海',
    //     'PortCode': 'SHA',
    //     'PortName': '上海虹橋機場',
    //     'CityNameX': '上海',
    //     'PortNameEn': 'Shanghai Hongqiao International Airport',
    //     'type': 1
    //   }
    //   ;
    // }

    state.flightStartCity = utils.storage.flightStartCity || ''
    state.flightEndCity = utils.storage.flightEndCity || ''
    this.bindFromCityData()
    this.bindToCityData()

    this.bindSeatPassengerData()
    this.bindDate()
    this.setLineType()

    nextTick(() => {
      this.setFlightCityTime()
    })
  },
  // 切换往返
  radioChage(index: number) {
    state.typeIndex = index
    if (state.typeIndex === 0) {
      state.DerrorMsg = ''
    }

    useCookie<number>('hopegoo_index_type').value = index

    this.allOnBlur()
  },
  getCityRef(): object {
    return refPopularCity.value || {}
  },

  /* 机票城市选择start */
  cityClick(type: number, el: any = '') {
    if (!state.isFromLine && !state.isToLine) {
      this.cityFocus(type, el)
    }
  },
  cityFocus(type: number, el: any = '') {
    this.allOnBlur(false)

    state.isFromLine = type === 1
    state.isToLine = type === 2

    state.QerrorMsg = ''
    state.isMask = true
    this.getCityRef().open(type)

    // 再次选中直接请求接口
    if (type === 1 && state.fromInfo && !state.flightStartCity) {
      this.getCityRef().searchSubmit(state.fromInfo)
    }

    if (type === 2 && state.toInfo && !state.flightEndCity) {
      this.getCityRef().searchSubmit(state.toInfo)
    }

    if (el) {
      // 光标定位要加上 setTimeOut，不然就会重新光标定位失败
      const obj = el.srcElement
      const len = obj.value.length
      setTimeout(() => {
        obj.selectionStart = obj.selectionEnd = len
      }, 60)
    }
  },
  // 城市输入框
  cityInput(type: number, e) {
    clearTimeout(this.cityInputTimer)
    type === 2 ? (state.flightEndCity = '') : (state.flightStartCity = '')
    state.inputValue = e.currentTarget.value || ''
    this.cityInputTimer = setTimeout(() => {
      state.inputValue.length ? this.getCityRef().searchSubmit(state.inputValue) : this.getCityRef().open(type)
    }, 250)
  },
  // 出发到达城市互换
  cityChange() {
    state.isAnimate = true
    this.timer && clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const { flightStartCity, flightEndCity } = state
      state.isAnimate = false

      this.setFlightStartCity(flightEndCity)
      this.setFlightEndCity(flightStartCity)
      this.allOnBlur(false)
    }, 300)
  },
  // 判断出发城市和目的城市是否相同
  judgeSameCity() {
    const { flightStartCity, flightEndCity, fromInfo, toInfo } = state
    const startCityCode = flightStartCity && flightStartCity.CityCode
    const endCityCode = flightEndCity && flightEndCity.CityCode
    if (fromInfo && toInfo) {
      if ((startCityCode || startCityCode) && startCityCode === endCityCode) {
        state.QerrorMsg = utils.tc('f_iflight_bediff')
      }
      else {
        state.QerrorMsg = ''
      }
    }
  },
  // 设置机票出发城市
  setFlightStartCity(city: any = '', ment = false) {
    state.flightStartCity = utils.storage.flightStartCity = city
    utils.setStorage()

    this.setLineType()
    this.bindFromCityData()
    nextTick(() => {
      this.getFlightStartCityTime()
    })

    if (ment) {
      setTimeout(() => {
        this.cityFocus(2) // 打开到达地城市弹窗，延迟
      }, 10)
    }
  },
  // 设置机票到达城市
  setFlightEndCity(city: any = '') {
    state.flightEndCity = utils.storage.flightEndCity = city
    utils.setStorage()

    this.setLineType()
    this.bindToCityData()
    nextTick(() => {
      this.getFlightEndCityTime()
    })
  },
  // 设置城市类型
  setLineType() {
    const { flightStartCity, flightEndCity } = state
    if (flightStartCity && flightEndCity) {
      state.flightLineType = (flightStartCity.type === 1 && flightEndCity.type === 1) ? 1 : 0
    }
    else {
      state.flightLineType = 0
    }
  },
  // 绑定出发城市页面数据
  bindFromCityData() {
    if (state.flightStartCity != null) {
      if (utils.getCityCode(state.flightStartCity)) {
        let fromCode = `(${utils.tc('f_iflight_cityname')})`
        let fromValue = utils.getCityName(state.flightStartCity)
        const nameType = state.flightStartCity.nameType
        if (nameType === '2' || nameType === '3') {
          fromValue = state.flightStartCity.PortName || fromValue
          fromCode = `(${utils.getCityCode(state.flightStartCity)})`
        }
        state.fromInfo = fromValue + fromCode

        state.QerrorMsg = ''
      }
      else {
        state.fromInfo = ''
        if (!state.isFromLine && !state.isToLine) {
          state.QerrorMsg = utils.tc('f_iflight_depart')
        }
      }
      this.judgeSameCity()
    }
  },
  // 绑定到达城市页面数据
  bindToCityData() {
    if (state.flightEndCity != null) {
      if (utils.getCityCode(state.flightEndCity)) {
        let toCode = `(${utils.tc('f_iflight_cityname')})`
        let toValue = utils.getCityName(state.flightEndCity)
        const nameType = state.flightEndCity.nameType
        if (nameType === '2' || nameType === '3') {
          toValue = state.flightEndCity.PortName || toValue
          toCode = `(${utils.getCityCode(state.flightEndCity)})`
        }
        state.toInfo = toValue + toCode

        state.QerrorMsg = ''
      }
      else {
        state.toInfo = ''
        if (state.flightStartCity != null) {
          if (utils.getCityCode(state.flightStartCity)) {
            if (!state.isFromLine && !state.isToLine) {
              state.QerrorMsg = utils.tc('f_iflight_arrival')
            }
          }
          else {
            if (!state.isFromLine && !state.isToLine) {
              state.QerrorMsg = utils.tc('f_iflight_depart')
            }
          }
        }
      }
      this.judgeSameCity()
    }
  },
  // 设置时区
  setFlightCityTime() {
    state.flightLineType === 0 && this.getFlightStartCityTime()
    state.flightLineType === 0 && this.getFlightEndCityTime()
  },
  // 设置出发城市的可选时间
  setFlightStartCityTime(date: any) {
    if (!date) { date = new Date() }
    date.setHours(0, 0, 0, 0)
    state.flightStartCityTime = date
  },
  // 设置到达城市的可选择时间
  setFlightEndCityTime(date: any) {
    if (!date) { date = new Date(state.flightGoDate.date.join('/')) }
    date.setHours(0, 0, 0, 0)
    state.flightEndCityTime = date
  },
  // 获取出发城市的可选时间
  async getFlightStartCityTime(date = state.flightGoDate.date.join('-')) {
    if (state.flightStartCity && state.flightEndCity) {
      const obj = {
        url: '/pcapi/city/localinfo', // 请求api接口地址
        method: 'POST',
        body: {
          queryType: 0,
          dateTime: date,
          departureCityCode: state.flightStartCity.CityCode,
          arrivalCityCode: state.flightEndCity.CityCode,
        },
      }

      let data = await http(obj)
      data = (data || {}).data || {}

      if (!(data && data.remoteTime)) { return }
      const d = new Date(data.remoteTime.replace(/-/g, '/'))
      d.setHours(0, 0, 0, 0)

      this.setFlightStartCityTime(d)
      if (d > new Date(state.flightGoDate.date.join('/'))) {
        this.setFlightGoDate(d)
      }
    }
  },
  // 获取到达城市的可选时间
  async getFlightEndCityTime(date = state.flightGoDate.date.join('-')) {
    if (state.flightStartCity && state.flightEndCity) {
      const obj = {
        url: '/pcapi/city/localinfo', // 请求api接口地址
        method: 'POST',
        body: {
          queryType: 1,
          dateTime: date,
          departureCityCode: state.flightStartCity.CityCode,
          arrivalCityCode: state.flightEndCity.CityCode,
        },
      }

      let data = await http(obj)
      data = (data || {}).data || {}

      if (!(data && data.remoteTime)) { return }
      const d = new Date(data.remoteTime.replace(/-/g, '/'))
      d.setHours(0, 0, 0, 0)

      this.setFlightEndCityTime(d)
      if (state.flightBackDate && d > new Date(state.flightBackDate.date.join('/'))) {
        this.setFlightBackDate(d)
      }
    }
  },
  /* 机票城市选择end */

  /* 机票日历选择start */
  dateFocus(type: number, e = '') {
    this.allOnBlur()

    state.isMask = true
    state.DerrorMsg = ''
    state.isGoDateLine = type === 1
    state.isBackDateLine = type === 2

    type === 2 ? this.backDateOpen(type) : this.goDateOpen(type)
  },
  async getHoliday() {
    if (state.festivals[utils.curLang]) { return state.festivals[utils.curLang] }
    return new Promise<void>(async (resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, 200)
      const obj = {
        url: '/pcapi/holiday/query', // 请求api接口地址
        method: 'GET',
        query: {
          yearStart: '2023',
          yearEnd: '2024',
        },
      }

      const fecthData = await http(obj)
      const holidayList = fecthData.data || []; const festivals = {}
      holidayList.forEach((item, index) => {
        festivals[item.date] = item.name
      })
      state.festivals[utils.curLang] = festivals
      resolve()
    })
  },
  async getPrice(type) {
    const isTwo = state.typeIndex === 1 // 往返
    const isTwo_Back = type === 2 // 往返 返程

    const currencyCookies = useComCookie('currency').value || ''
    const localeCookies = useComCookie('locale').value || ''
    const language = localeCookies.split('-')[1]
    if (!state.flightStartCity || !state.flightEndCity) { return }

    let fromCode = utils.getCityCode(state.flightStartCity)
    let toCode = utils.getCityCode(state.flightEndCity)

    fromCode = cityCodeHash[fromCode] || fromCode
    toCode = cityCodeHash[toCode] || toCode

    const startDate = isTwo ? state.flightGoDate.day_s : utils.getFormatDate(state.flightStartCityTime).day_s
    const params = {
      d: fromCode,
      a: toCode,
      dd: startDate, // 起飞日期
      bd: isTwo_Back ? state.flightGoDate.day_s : utils.getFormatDate(state.flightStartCityTime).day_s, // 价格查询开始日期
      ed: utils.getFormatDate(utils.threeMonthAfterToday(new Date(startDate), isTwo ? 1 : 3)).day_s, // 价格查询结束日期
      tt: isTwo ? 'RT' : 'OW', // OW—单，RT—往返
      st: isTwo ? 2 : 1, // 1单 2往返, // 1去程 2返程
      currency: currencyCookies,
      language,
      lang: language,
    }

    // if (state.typeIndex === 1) {
    //   const end = new Date(state.flightGoDate.day)
    //   end.setDate(end.getDate() + 89)
    //   params = {
    //     d: fromCode,
    //     a: toCode,
    //     bd: state.flightGoDate.day_s, // 出发时间
    //     dd: state.flightGoDate.day_s, // 出发城市日期
    //     ad: utils.getFormatDate(end).day_s, // 到达城市时间
    //     tt: state.typeIndex === 1 ? 'RT' : 'OW', // OW—单，RT—往返
    //     st: state.typeIndex === 1 ? 2 : 1, // 1单 2往返
    //     currency: currencyCookies,
    //     language,
    //     lang: language,
    //   }
    // }

    const obj = {
      url: '/sea_shell/sea_octopus_api/flight/getLowPriceCalendar',
      method: 'POST',
      body: params,
      headers: {
        'intl-token': 1,
      },
    }

    let data = await http(obj)
    data = (data || {}).body || {}
    if (!(data && data.RD && data.RD.length)) { return }
    const prices1 = {}
    data.RD.forEach((n) => {
      n.price = n.TP === '0' ? '' : n.TP
      n.monthlowestprice = n.ML === '1'
      try {
        n.price = utils.unitConverter(n.price)
        n.price = n.price === '0' ? '' : n.price
      }
      catch (e) { }
      prices1[n.DD] = n
    })
    state.prices = prices1
    calObj1.updateDom({
      prices: state.prices,
      showPrice: true,
    })
  },
  async goDateOpen(type) {
    const cDate = new Date(state.flightGoDate.day)
    cDate.setDate(cDate.getDate())

    // await this.getHoliday() // 请求节日
    if (state.typeIndex === 0) {
      this.getPrice(type)
    }

    calObj1.pick({
      elem: '.godate-box',
      startDate: state.flightStartCityTime,
      endDate: utils.endDate(),
      currentDate: [cDate],
      unsyncValue: true,
      offset: {
        top: -8,
        left: 3,
      },
      festivals: state.festivals[utils.curLang] || [],
      prices: {},
      showPrice: false,
      fn: (chooseDate, td, elem) => {
        this.setFlightGoDate(new Date(chooseDate.day))
        if (state.flightBackDate) {
          const beforeDate = new Date(state.flightGoDate.day)
          const afterDate = new Date(state.flightBackDate.day)
          if (beforeDate > afterDate) {
            this.setFlightBackDate()
          }
        }
        this.setFlightCityTime()
        state.typeIndex === 1 && this.dateFocus(2)
        $utils.sendEvent('', '', '机票主搜', `^出发日期^${chooseDate.day}`)
      },
    })
  },
  async backDateOpen(type) {
    const cDate = new Date(state.flightGoDate.day)
    let lDate, currentDateObj
    if (state.flightBackDate) {
      lDate = new Date(state.flightBackDate.day)
      if (cDate > lDate) {
        this.setFlightBackDate()
        currentDateObj = [cDate]
      }
      else {
        lDate.setDate(lDate.getDate())
        currentDateObj = [cDate, lDate]
      }
    }
    else {
      currentDateObj = [cDate]
    }
    let position = {
      top: -8,
      left: 3,
    }
    if (document.body.clientWidth < 1496) {
      position = {
        top: -8,
        left: -24,
      }
    }

    // await this.getHoliday() // 请求节日

    if (state.typeIndex === 1) {
      this.getPrice(type)
    }

    calObj1.pick({
      elem: '.backdate-box',
      startDate: state.flightStartCityTime,
      endDate: utils.endDate(),
      currentDate: currentDateObj,
      mode: 'rangeTo',
      unsyncValue: true,
      offset: position,
      festivals: state.festivals[utils.curLang] || [],
      prices: [],
      fn: (chooseDate, td, elem) => {
        state.DerrorMsg = ''
        this.setFlightBackDate(new Date(chooseDate.day))
        this.setFlightCityTime()
        $utils.sendEvent('', '', '机票主搜', `^到达日期^${chooseDate.day}`)
      },
    })
  },
  // 设置机票出发日期
  setFlightGoDate(date: any = '', store = true) {
    state.flightGoDate = utils.getFormatDate(date)

    if (utils.curLang === 'zh-hk' || utils.curLang === 'zh-tw') {
      state.startDate = `${state.flightGoDate.date[1]}月${state.flightGoDate.date[2]}日 ${state.flightGoDate.week}`
    }
    else if (utils.curLang === 'ja-jp') {
      state.startDate = `${state.flightGoDate.date[1]}月${state.flightGoDate.date[2]}日 ${state.flightGoDate.week}`
    }
    else {
      state.startDate = `${state.flightGoDate.week}, ${state.flightGoDate.enMonth} ${state.flightGoDate.date[2]}`
    }
    state.isGoDateLine = false

    if (store) {
      utils.storage.flightGoDate = state.flightGoDate.day || ''
      utils.setStorage()
    }
  },
  // 设置机票返回日期
  setFlightBackDate(date: any = '', store = true) {
    if (date) {
      state.flightBackDate = utils.getFormatDate(date)

      if (utils.curLang === 'zh-hk' || utils.curLang === 'zh-tw') {
        state.backDate = `${state.flightBackDate.date[1]}月${state.flightBackDate.date[2]}日 ${state.flightBackDate.week}`
      }
      else if (utils.curLang === 'ja-jp') {
        state.backDate = `${state.flightBackDate.date[1]}月${state.flightBackDate.date[2]}日 ${state.flightBackDate.week}`
      }
      else {
        state.backDate = `${state.flightBackDate.week}, ${state.flightBackDate.enMonth} ${state.flightBackDate.date[2]}`
      }
    }
    else {
      state.flightBackDate = ''
      state.backDate = ''
    }
    state.isBackDateLine = false

    if (store) {
      utils.storage.flightGoDate = state.flightGoDate.day || ''
      utils.storage.flightBackDate = state.flightBackDate.day || ''
      utils.setStorage()
    }
  },
  // 绑定机票日历数据
  bindDate() {
    const { flightGoDate: s_flightGoDate, flightBackDate: s_flightBackDate } = utils.storage
    const add14 = new Date(new Date().setDate(new Date().getDate() + 14))
    let flightGoDate = add14; let flightBackDate = ''

    if (s_flightGoDate) {
      if (new Date(s_flightGoDate).toDateString() === new Date().toDateString()
        || new Date(s_flightGoDate).getTime() > new Date().getTime()) { // 有效
        flightGoDate = s_flightGoDate
      }
    }

    if (s_flightBackDate && utils.typeOfDate(new Date(s_flightBackDate)) && new Date(s_flightBackDate).getTime() > new Date(flightGoDate).getTime()) {
      flightBackDate = s_flightBackDate
    }

    this.setFlightGoDate(flightGoDate, false)
    this.setFlightBackDate(flightBackDate, false)

    const today = new Date(Date.now() + utils.serverTimeDiff)
    state.flightStartCityTime = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    state.flightEndCityTime = new Date(state.flightGoDate.date.join('/'))
  },
  /* 机票日历选择end */

  /* 选择舱等start */
  seatFocus(e: Event) {
    this.allOnBlur()

    state.showSeatPassenger = true
    state.isSeatLine = true
    state.isMask = true
  },
  seatItemClick(index: number) {
    state.flightSeatIndex = index
    state.flightSeat = state.flightSeatList[index]
    this.bindSeatPassengerData()

    $utils.sendEvent('', '', '机票主搜', `^${state.seatPassenger}^`)
  },
  seatNumClick(type: string, category: string) {
    switch (category) {
      case 'adult':
        if (type === 'plus') {
          this.setAdult(state.flightAdult + 1)
        }
        else {
          this.setAdult(state.flightAdult - 1)
        }
        break
      case 'child':
        if (type === 'plus') {
          this.setChild(state.flightChild + 1)
        }
        else {
          this.setChild(state.flightChild - 1)
        }
        break
    }
    this.bindSeatPassengerData()
    $utils.sendEvent('', '', '机票主搜', `^${state.seatPassenger}^`)
  },
  // 设置成人
  setAdult(num: number) {
    state.flightAdult = num < 1 ? 1 : num > 9 ? 9 : num
    if (state.flightAdult + state.flightChild > 9) {
      state.flightAdult = 9 - state.flightChild // 成人加儿童 最多9个
      state.isSeatError = true
    }
    this.setChild(state.flightChild)
  },
  // 设置儿童
  setChild(num: number) {
    state.flightChild = num < 0 ? 0 : num
    if (state.flightAdult + state.flightChild > 9) {
      state.flightChild = 9 - state.flightAdult // 成人加儿童 最多9个
      state.isSeatError = true
    }
    else if (state.flightAdult + state.flightChild === 9) {
      state.isSeatError = true
    }
    else {
      state.isSeatError = false
    }
    if (state.flightChild && state.flightChild / state.flightAdult > 2) { state.flightChild = state.flightAdult * 2 } // 1成人最多带2儿童
  },
  // 绑定座位、人数
  bindSeatPassengerData() {
    state.seatPassenger = `${utils.tc(state.flightSeat.txt)}，${state.flightAdult} ${utils.tc('f_iflight_adult')} ${state.flightChild} ${utils.tc('f_iflight_child')} `
  },
  allOnBlur(ment = true) {
    state.isMask = false
    if (ment) {
      this.bindFromCityData()
      this.bindToCityData()
    }
    else {
      this.getCityRef().state.searchCitys = [] // 兼容上次选择。。。
    }

    this.destinationOnBlur()
    this.dateOnBlur()
    this.seatOnBlur()
  },
  // 出发地、目的地失去焦点数据判断
  destinationOnBlur() {
    const searchCitys = this.getCityRef().state.searchCitys
    if (state.entrance && searchCitys && searchCitys.length) {
      if (searchCitys[0].error === 1) { // 无结果
        state.entrance === 1 ? this.setFlightStartCity() : this.setFlightEndCity()
      }
      else { // 有结果
        const item = searchCitys[0]
        if (item.clickable === '1') {
          state.entrance === 1 ? this.setFlightStartCity(item) : this.setFlightEndCity(item)
        }
        else if (item.clickable === '0') {
          if (item.list && item.list.length) {
            state.entrance === 1 ? this.setFlightStartCity(item.list[0]) : this.setFlightEndCity(item.list[0])
          }
        }
      }
    }

    if (!state.fromInfo) {
      state.QerrorMsg = utils.tc('f_iflight_depart')
    }

    if (!state.toInfo && state.fromInfo) {
      state.QerrorMsg = utils.tc('f_iflight_arrival')
    }

    this.cityOnBlur()
  },
  // 城市弹窗失去焦点关闭
  cityOnBlur() {
    state.isFromLine = false
    state.isToLine = false
    this.getCityRef().close()
  },
  // 日期失去焦点数据判断
  dateOnBlur() {
    state.isGoDateLine = false
    state.isBackDateLine = false
    setTimeout(() => {
      calObj1.hide()
    }, 0)
  },
  // 选择舱等失去焦点
  seatOnBlur() {
    state.showSeatPassenger = false
    state.isSeatLine = false
  },
  search() {
    this.allOnBlur()
    const { flightStartCity, flightEndCity, flightGoDate, flightBackDate, typeIndex, flightAdult, flightChild, flightSeat } = state
    if (!flightStartCity) {
      state.QerrorMsg = utils.tc('f_iflight_depart')
      return
    }

    if (!flightEndCity) {
      state.QerrorMsg = utils.tc('f_iflight_arrival')
      return
    }

    if (typeIndex === 1 && !flightBackDate) {
      state.DerrorMsg = utils.tc('f_iflight_choose_return_date')
      return
    }

    let startCityCode = flightStartCity.CityCode
    let endCityCode = flightEndCity.CityCode
    const startCityName = flightStartCity.CityName || ''
    const endCityName = flightEndCity.CityName || ''
    const goDate = flightGoDate.day_s // 单数前面补0
    const backDate = typeIndex === 1 ? flightBackDate.day_s : ''
    const direction = (typeIndex === 1 && flightBackDate) ? '1' : '0'
    const adult = flightAdult
    const child = flightChild
    const seat = flightSeat.value

    const startPortCode = flightStartCity.PortCode || ''
    const endPortCode = flightEndCity.PortCode || ''

    if (!startCityCode || !endCityCode) {
      state.QerrorMsg = utils.tc('f_iflight_noresult')
      return
    }

    if (startCityCode === endCityCode) {
      state.QerrorMsg = utils.tc('f_iflight_bediff')
      return
    }

    if (state.flightLineType === 1) { // 国内
      $utils.sendEvent('', '', '机票主搜', '^国内查询^')
      showConfirmDialog({
        width: '460px',
        overlayStyle: {
          background: 'rgba(0,0,0,.4)',
        },
        title: '',
        className: 'iflight',
        cancelButtonText: utils.tc('f_iflight_cancel'),
        confirmButtonText: utils.tc('f_iflight_swith'),
        lockScroll: false,
        closeOnClickOverlay: true,
        message: utils.tc('f_iflight_switch_flight'),
      })
        .then(() => {
          // on confirm
          window.open('https://www.ly.com')
        })
        .catch(() => {
          // on cancel
        })
    }
    else { // 国际
      startCityCode = cityCodeHash[startCityCode] || startCityCode
      endCityCode = cityCodeHash[endCityCode] || endCityCode
      const refid = $utils.getRefid() || ''

      let url = `${utils.iflightUrl()}/iflight/${startCityCode.toLowerCase()}-${endCityCode.toLowerCase()}?para=${direction}*${startCityCode}*${endCityCode}*${goDate}*${backDate}*${seat}*${adult}*${child}*1&refid=${refid}`

      if (startPortCode && endPortCode) {
        url += `&orgAirCode=${startPortCode}&DesAirCode=${endPortCode}`
      }
      if (startPortCode && !endPortCode) {
        url += `&orgAirCode=${startPortCode}`
      }
      if (!startPortCode && endPortCode) {
        url += `&DesAirCode=${endPortCode}`
      }
      if (query.thirdMemberId) {
        url += `&thirdMemberId=${query.thirdMemberId}`
      }

      $utils.go(jumpToRefidUrl(url))
      const value = `出发城市:${startCityName}^到达城市:${endCityName}^出发日期:${goDate}^到达日期:${backDate}^成人:${adult}^儿童:${child}^舱等:${seat}^出发城市三字码:${startCityCode}^到达城市三字码:${endCityCode}^${direction === '1' ? '往返' : '单程'}`
      $utils.sendEvent('', '', '机票主搜', `^查询^${value}`)
    }
  },
  setData(params = {}) {
    for (const key in params) {
      state[key] = params[key]
    }
  },

}

onMounted(() => {
  methods.init()
})

provide(symbolKeys.flightState, readonly(state))
provide(symbolKeys.flightMethods, readonly(methods))
</script>

<template>
  <div>
    <!-- 机票搜索iflight.begin -->
    <div class="flight-content item_wrapper">
      <div class="flight-wrapper">
        <form>
          <div>{{ state.value }}</div>
          <div class="travel_type">
            <span class="type">
              <input
                id="ow" type="radio" name="type" value="0" :checked="state.typeIndex === 0"
                @click="methods.radioChage(0)"
              >
              <label for="ow">{{ utils.tc('b_oneway') }}</label>
            </span>
            <span class="type">
              <input
                id="rt" type="radio" name="type" value="1" :checked="state.typeIndex === 1"
                @click="methods.radioChage(1)"
              >
              <label for="rt">{{ utils.tc('b_roundtrip') }}</label>
            </span>
          </div>
          <!-- 出发地、目的地 -->
          <div class="query-city">
            <ul>
              <li class="from-box" :class="state.isFromLine ? 'is-line' : ''">
                <div class="input-box">
                  <input
                    id="fromCity" v-model="state.fromInfo" type="text" class="truncate new-city"
                    :class="state.isAnimate ? 'to_right' : ''" autocomplete="off" ondblclick="this.select()"
                    :placeholder="state.b_iflight_from" @focus="methods.cityFocus(1, $event)"
                    @click="methods.cityClick(1, $event)" @input="methods.cityInput(1, $event)"
                  >
                </div>
              </li>
              <li class="btn-change" @click="methods.cityChange" />
              <li class="to-box" :class="state.isToLine ? 'is-line' : ''">
                <div class="input-box">
                  <input
                    id="toCity" v-model="state.toInfo" type="text" class="truncate new-city"
                    :class="state.isAnimate ? 'to_left' : ''" autocomplete="off" ondblclick="this.select()"
                    :placeholder="state.b_iflight_to" @focus="methods.cityFocus(2, $event)"
                    @click="methods.cityClick(2, $event)" @input="methods.cityInput(2, $event)"
                  >
                </div>
              </li>
            </ul>
            <p v-if="state.QerrorMsg" class="error-msg">
              <span>{{ state.QerrorMsg }}</span>
            </p>
          </div>
          <!-- 出发时间、结束时间 -->
          <div class="query-date">
            <ul>
              <li
                class="godate-box" :class="state.isGoDateLine ? 'is-line' : ''"
                :style="state.typeIndex === 0 ? 'width: 300px;' : ''"
              >
                <div class="input-box">
                  <input
                    id="goDate" type="text" class="new-city" autocomplete="off"
                    :placeholder="state.b_iflight_departure" :value="state.startDate" readonly="readonly"
                    @focus="methods.dateFocus(1, $event)"
                  >
                </div>
              </li>
              <li class="line" :style="state.typeIndex === 0 ? 'display: none;' : ''" />
              <li
                class="backdate-box" :class="state.isBackDateLine ? 'is-line' : ''"
                :style="state.typeIndex === 0 ? 'display: none;' : ''"
              >
                <div class="input-box">
                  <input
                    id="backDate" type="text" class="new-city" autocomplete="off"
                    :placeholder="state.b_iflight_return" :value="state.backDate" readonly="readonly"
                    @focus="methods.dateFocus(2, $event)"
                  >
                </div>
              </li>
            </ul>
            <p v-if="state.DerrorMsg" class="error-msg">
              <span>{{ state.DerrorMsg }}</span>
            </p>
          </div>
          <!-- 乘客、座位 -->
          <div class="query-passenger">
            <ul>
              <li class="query-passenger-box" :class="state.isSeatLine ? 'is-line' : ''">
                <div>
                  <input
                    id="seatPassenger" type="text" class="new-city pdl-12 pdr-12" autocomplete="off" readonly=""
                    :value="state.seatPassenger" :placeholder="state.b_iflight_class_passenger"
                    @focus="methods.seatFocus($event)"
                  >
                </div>
                <span class="icon-arrow" />
              </li>
            </ul>
            <!-- 舱位选择\乘机人数选择 -->
            <div v-show="state.showSeatPassenger" class="seat-passenger-wrapper box-dow">
              <!-- 舱位选择 -->
              <div class="seat-wrapper">
                <ul class="seat-ul">
                  <li
                    v-for="(item, index) in state.flightSeatList" :key="item" class="round-li"
                    :class="state.flightSeatIndex === index ? 'selected' : ''" :data-index="index"
                    @click="methods.seatItemClick(index)"
                  >
                    {{ utils.tc(item.txt) }}
                  </li>
                </ul>
              </div>
              <!-- 乘机人数选择 -->
              <div class="select-ticket-wrapper">
                <div class="line" />
                <div class="option-box mar-b20">
                  <div class="option">
                    <div class="left_box">
                      <p class="p1">
                        {{ utils.tc('f_iflight_adult') }}
                      </p>
                      <p class="p2">
                        {{ utils.tc('b_iflight_over_12') }}
                      </p>
                    </div>
                    <div class="num_box clear">
                      <span
                        class="num_btn minus" :class="state.flightAdult > 1 ? 'active' : ''"
                        @click="methods.seatNumClick('minus', 'adult')"
                      />
                      <span id="adultNum" class="num_txt">{{ state.flightAdult }}</span>
                      <span
                        class="num_btn plus"
                        :class="state.flightAdult < 9 && state.flightAdult + state.flightChild < 9 ? 'active' : ''"
                        @click="methods.seatNumClick('plus', 'adult')"
                      />
                    </div>
                  </div>
                  <div class="option">
                    <div class="left_box">
                      <p class="p1">
                        {{ utils.tc('f_iflight_child') }}
                      </p>
                      <p class="p2">
                        {{ utils.tc('b_iflight_2_to_12') }}
                      </p>
                    </div>
                    <div class="num_box clear">
                      <span
                        class="num_btn minus" :class="state.flightChild > 0 ? 'active' : ''"
                        @click="methods.seatNumClick('minus', 'child')"
                      />
                      <span id="childNum" class="num_txt">{{ state.flightChild }}</span>
                      <span
                        class="num_btn plus"
                        :class="!((state.flightChild && state.flightChild / state.flightAdult >= 2) || (state.flightAdult + state.flightChild >= 9)) ? 'active' : ''"
                        @click="methods.seatNumClick('plus', 'child')"
                      />
                    </div>
                  </div>
                </div>
                <p v-if="state.isSeatError" class="warning">
                  {{ utils.tc('b_iflight_max_9_passengers') }}
                </p>
              </div>
            </div>
          </div>
          <div class="btn-search" data-btn-search-type="flight" @click="methods.search">
            {{ utils.tc('s_hotel_search') }}
          </div>
          <!-- 单程往返 -->
        </form>
        <div v-if="false" class="non_stop none" isnonstop="1">
          直飛
        </div>
      </div>
      <IndexPopularCity ref="refPopularCity" />
    </div>
    <!--    <div class="mask" v-if="state.isMask" @click="methods.allOnBlur"></div> -->
    <!-- 机票搜索iflight.end -->
  </div>
</template>

<style lang="scss" scoped>
.flight-content {
  position: relative;
  z-index: 95;
  padding: 0 30px;
}

.flight-wrapper,
.train-wrapper {
  position: relative
}

.flight-wrapper .travel_type .type {
  display: inline-block;
  padding: 8px 0;
  line-height: 28px;
  margin: 10px 30px 0 0
}

.flight-wrapper .travel_type .type input {
  display: none;
  visibility: visible;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  text-shadow: none
}

.flight-wrapper .travel_type .type input:checked+label {
  // background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_ellipse_selected.png) no-repeat;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/iflight/img/flight_type_checked1.svg) no-repeat;
  display: inline-block;
  padding-left: 22px;
  line-height: 16px;
  background-size: 16px 16px;
  font-size: 16px;
  color: #000;
  font-weight: 500
}

.flight-wrapper .travel_type .type input+label {
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/iflight/img/flight_type1.svg) no-repeat;
  display: inline-block;
  padding-left: 22px;
  line-height: 16px;
  background-size: 16px 16px;
  font-size: 16px;
  color: #000;
  cursor: pointer
}

.flight-wrapper form li>div,
.train-wrapper form li>div {
  position: relative;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.flight-wrapper form li>div:before,
.train-wrapper form li>div:before {
  content: '';
  width: 0;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -ms-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  z-index: 11
}

.flight-wrapper form .from-box>div:before,
.flight-wrapper form .godate-box>div:before,
.train-wrapper form .from-box>div:before {
  -webkit-border-radius: 0 0 0 6px;
  -moz-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px
}

.flight-wrapper form .godate-box.line_radius>div:before {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px
}

.flight-wrapper form .backdate-box>div:before,
.flight-wrapper form .to-box>div:before,
.train-wrapper form .to-box>div:before {
  -webkit-border-radius: 0 0 6px 0;
  -moz-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0
}

.flight-wrapper form .query-passenger-box>div:before,
.train-wrapper form .godate-box>div:before {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px
}

.flight-wrapper form li.is-line>div:before,
.train-wrapper form li.is-line>div:before {
  left: 0;
  width: 100%;
  background: var(--Brand-Green)
}

.flight-wrapper .query-city,
.train-wrapper .query-station {
  position: relative;
  display: inline-block;
  width: 400px;
  height: 70px;
  background: #F7F8FB;
  border-radius: 8px
}

.flight-wrapper .query-city .from-box,
.flight-wrapper .query-city .to-box,
.train-wrapper .query-station .from-box,
.train-wrapper .query-station .to-box {
  display: inline-block;
  width: 192px
}

.flight-wrapper .query-city .from-box,
.flight-wrapper .query-city .to-box {
  width: 179px
}

.flight-wrapper .query-city .btn-change,
.train-wrapper .query-station .btn-change {
  vertical-align: top;
  display: inline-block;
  width: 42px;
  height: 70px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/iflight/img/flight_icon2.svg) no-repeat center center;
  -webkit-background-size: 42px 42px;
  background-size: 42px 42px;
  cursor: pointer
}

.flight-wrapper .query-city .btn-change:hover,
.train-wrapper .query-station .btn-change:hover {
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/iflight/img/flight_icon2.svg) no-repeat center center;
  -webkit-background-size: 42px 42px;
  background-size: 42px 42px
}
.flight-wrapper .query-passenger-box{
  background: #F7F8FB;
  border-radius: 8px;
}
.flight-wrapper .input-box .new-city,
.flight-wrapper .query-passenger-box input,
.train-wrapper .input-box .new-city {
  margin: 0;
  padding: 0 14px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  line-height: 40px;
  text-align: left;
  font-size: 16px;
  color: #333;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  text-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 700;
  background: #F7F8FB;
}

.flight-wrapper .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #F7F8FB;
}

.new-city::placeholder {
  color: #999;
}

.flight-wrapper .to_right,
.train-wrapper .to_right {
  opacity: 0;
  transform: translate3d(110%, 0, 0)
}

.flight-wrapper .to_left,
.train-wrapper .to_left {
  opacity: 0;
  transform: translate3d(-110%, 0, 0)
}

.flight-wrapper .query-date,
.train-wrapper .query-time {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 70px;
  background: #F7F8FB;
  margin-left: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 8px
}

.flight-wrapper .query-date .backdate-box,
.flight-wrapper .query-date .godate-box,
.train-wrapper .query-time .godate-box {
  display: inline-block;
  width: 145px;
  height: 70px
}

.flight-wrapper .query-date .line {
  display: inline-block;
  width: 10px;
  height: 1px;
  background: #333;
  margin-bottom: 4px
}

.flight-wrapper .query-passenger {
  display: inline-block;
  width: 260px;
  height: 70px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: relative;
  margin-left: 10px;
  background: #fff
}

.flight-wrapper .btn-search,
.train-wrapper .btn-search {
  vertical-align: top;
  display: inline-block;
  margin-left: 10px;
  width: 140px;
  height: 70px;
  line-height: 70px;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  background: var(--Brand-Green);
  cursor: pointer;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 600;
}

.flight-wrapper .error-msg,
.train-wrapper .error-msg {
  position: absolute;
  width: 100%;
  bottom: -33px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #ff0000;
  font-weight: 500;
  background-color: #FFF0EC;
}

.flight-wrapper .error-msg>span,
.train-wrapper .error-msg>span {
  display: inline-block;
  margin: 0 12px
}

.flight-wrapper .travel_type {
  margin-top: 0;
}

.flight-wrapper .non_stop {
  font-size: 14px;
  color: #fff;
  margin-top: 18px;
  margin-left: 2px;
  padding-left: 20px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_nostop_unselected.png) no-repeat left center;
  -webkit-background-size: 14px;
  background-size: 14px;
  cursor: pointer
}

.flight-wrapper .non_stop.selected {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmBwFfGco.png) no-repeat left center;
  -webkit-background-size: 14px;
  background-size: 14px
}

.search-city-wrapper {
  display: none;
  z-index: 100;
  position: absolute;
  top: 64px;
  padding: 20px 0;
  width: 420px;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px
}

.search-city-wrapper .search_click.list_hover {
  color: var(--Brand-Green);
  cursor: pointer
}

.search-city-wrapper .search_city {
  position: relative;
  margin-bottom: 11px;
  padding: 0 30px 0 44px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  line-height: 30px
}

.search-city-wrapper .search_city:before {
  content: '';
  position: absolute;
  left: 20px;
  width: 14px;
  height: 30px
}

.search-city-wrapper .icon_country::before {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmEwKrQ0E.png) no-repeat center center
}

.search-city-wrapper .icon_sheng::before {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmFj7QaBO.png) no-repeat center center
}

.search-city-wrapper .icon_city::before {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmHBP2LBe.png) no-repeat center center
}

.icon_air::before {
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/imagesb-pc/images/homepage/img/icon_air_01.png) no-repeat center center
}

.search-city-wrapper .search_country {
  margin-left: 20px;
  line-height: 30px;
  font-size: 12px;
  color: #999
}

.search-city-wrapper .search_air {
  position: relative;
  line-height: 30px;
  padding-left: 62px;
  padding-right: 20px;
  font-size: 14px;
  color: #666
}

.search-city-wrapper .search_air:before {
  content: '';
  position: absolute;
  left: 40px;
  width: 12px;
  height: 30px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_city_zhe.png) no-repeat center center;
  background-size: 12px 7px
}

.search-city-wrapper .search_near {
  padding-left: 44px;
  line-height: 20px;
  margin-bottom: 5px
}

.search-city-wrapper .highlight {
  color: var(--Brand-Green)
}

.btm_10 {
  margin-bottom: 10px
}

.near1 {
  vertical-align: top;
  display: inline-block;
  margin-top: 7px;
  width: 29px;
  height: 14px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/label_search_near.png)  no-repeat center ;
  background-size: 29px 14px
  
}

.near2 {
  display: inline-block;
  width: 306px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  font-size: 14px;
  color: #666;
  position: relative
}

.near2 .showtxt {
  display: inline-block;
  width: 260px
}

.near2 .distance {
  position: absolute;
  right: 0;
  top: 4px;
  color: #ccc;
  font-size: 12px
}

.search-city-wrapper .no_city {
  padding-left: 20px;
  line-height: 16px;
  font-size: 16px;
  color: #666
}

.popular-city-wrapper {
  display: none;
  z-index: 99;
  position: absolute;
  top: 64px;
  width: 580px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 14px
}

.popular-city-wrapper ul>li>div {
  padding: 0 10px
}

.popular-city-wrapper .area-title {
  margin-bottom: 18px;
  padding: 0 20px;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  font-weight: 700;
  color: #333;
  background: #f4f6f9
}

.popular-city-wrapper ul>li:first-child .area-title {
  border-radius: 6px 6px 0 0
}

.popular-city-wrapper .mNotice-normal {
  float: left;
  cursor: pointer;
  display: block;
  height: 26px;
  line-height: 26px;
  padding: 0 0 12px 0;
  margin: -3px 20px 0 0;
  width: 120px;
  color: #666;
  white-space: nowrap;
  overflow: hidden
}

.popular-city-wrapper .mNotice-normal em {
  display: inline-block;
  padding: 0 10px;
  height: 26px;
  line-height: 26px;
  max-width: 100%;
  font-style: normal;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.popular-city-wrapper .mNotice-normal em:hover {
  color: var(--Brand-Green)
}

.popular-city-wrapper em.current {
  font-weight: 500;
  color: var(--Brand-Green);
  box-sizing: border-box
}

.seat-passenger-wrapper {
  z-index: 99;
  position: absolute;
  top: 70px;
  left: 0;
  width: 320px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-top: 4px;
  background-color: #fff
}

.seat-wrapper {
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px
}

.seat-wrapper .round-li {
  padding: 0 20px;
  min-height: auto;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  color: #666;
  cursor: pointer
}

.seat-wrapper .round-li:hover {
  color: var(--Brand-Green)
}

.seat-wrapper .round-li.selected {
  color: var(--Brand-Green);
  font-weight: 500;
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmIE1qNJm.png) no-repeat 93% center;
  -webkit-background-size: 17px 14px;
  background-size: 17px 14px
}

.seat-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 16px;
  background-color: #f4f6f9
}

.seat-wrapper::-webkit-scrollbar-thumb {
  width: 8px;
  height: 80px;
  background: #ccc;
  border-radius: 5px
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.select-ticket-wrapper {
  box-sizing: border-box;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px
}

.select-ticket-wrapper .option {
  position: relative;
  height: 50px;
  margin: 0 20px;
  box-sizing: border-box
}

.select-ticket-wrapper .option-box {
  margin-bottom: 15px
}

.select-ticket-wrapper .line {
  margin: 0 20px 10px;
  height: 1px;
  border: none;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/inquiry/list/imgs/inquiry_list_line.png) repeat-x center top
}

.select-ticket-wrapper .left_box {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%)
}

.select-ticket-wrapper .p1 {
  font-size: 16px;
  color: #333
}

.select-ticket-wrapper .p2 {
  padding-top: 3px;
  font-size: 12px;
  color: #999
}

.select-ticket-wrapper .num_box {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)
}

.select-ticket-wrapper .num_btn {
  position: relative;
  float: left;
  width: 24px;
  height: 24px;
  border-radius: 12px
}

.select-ticket-wrapper .minus {
  background: #f5f5f5 url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/unclick_minus.png) no-repeat 50% 50%;
  background-size: 12px 2px
}

.select-ticket-wrapper .minus.active {
  background: var(--Selected-Green) url(//pic5.hopegoocdn.com/i/ori/1xjmKwXoxNK.png) no-repeat 50% 50%;
  background-size: 12px 2px;
  cursor: pointer
}

.select-ticket-wrapper .minus.active:hover {
  background: var(--Brand-Green) url('//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/hover_minus_new.png?a=1') no-repeat 50% 50%;
  background-size: 12px 2px
}

.select-ticket-wrapper .plus {
  background: #f5f5f5 url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/unclick_plus.png) no-repeat 50% 50%;
  background-size: 12px 12px
}

.select-ticket-wrapper .plus.active {
  background: #EBF9F1 url(//pic5.hopegoocdn.com/i/ori/1xjmLlgzBi8.png) no-repeat 50% 50%;
  background-size: 12px 12px;
  cursor: pointer
}

.select-ticket-wrapper .plus.active:hover {
  background: var(--Brand-Green) url('//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/hover_plus_new.png?a=1') no-repeat 50% 50%;
  background-size: 12px 12px
}

.select-ticket-wrapper .num_txt {
  float: left;
  width: 40px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  color: #333
}

.select-ticket-wrapper .warning {
  margin: 0 20px 8px;
  font-size: 12px;
  line-height: 12px;
  color: #ff5346
}

.box-dow {
  box-shadow: 0 0 10px 1px rgba(84, 95, 141, .2);
}
</style>

<style>
.iflight .van-dialog__message {
  font-size: 15px;
  line-height: 1.6;
  padding: 33px 50px;
}

.iflight .van-dialog__confirm {
  background: var(--Brand-Green);
  color: #fff;
}
</style>
