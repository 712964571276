<script setup lang="ts">
// import { debounce } from 'lodash-es'
import { en_us_city, ja_jp_city, zh_hk_city, zh_tw_city } from './flight_hotcity.ts'
import utils from './utils'

const pstate = inject(symbolKeys.flightState)
const pmethods = inject(symbolKeys.flightMethods)

const state = reactive({
  curLang: utils.curLang,
  flightHotCitys: [],
  searchCitys: [],
  entrance: 0,
  isPopCity: false,
  isSearchCity: false,
  leftPx: '0',

  // 搜索文案
  searchTxt: '',
  // 搜索关键词
  keywordTokens: '',
})

// 事件
const methods = {
  async init() {
    let flightHotCities
    switch (state.curLang) {
      case 'zh-hk':
        flightHotCities = zh_hk_city
        break
      case 'zh-tw':
        flightHotCities = zh_tw_city
        break
      case 'en-us':
        flightHotCities = en_us_city
        break
      case 'ja-jp':
        flightHotCities = ja_jp_city
        break
      default:
        flightHotCities = en_us_city
        break
    }

    const list = []; let num = 0
    flightHotCities.forEach((item) => {
      const cityList = []
      item.list.forEach((n, i) => {
        num++
        cityList.push({
          CityCode: n.code,
          CityName: n.name,
          CityNameX: n.nameX,
          type: n.isInternational === '0' ? 1 : 0, // type 1国内 0国际
          hasAirPort: '1',
          cityIndex: num,
        })
      })
      list.push({
        areaTitle: item.title,
        cityList,
      })
    })
    this.setFlightHotCitys(list)
  },
  // 存热门城市数据
  setFlightHotCitys(list = []) {
    state.flightHotCitys = { // state.flightHotCitys = utils.storage.flightHotCitys
      list,
      version: utils.getFormatDate().version,
    }
    // utils.setStorage()
  },
  open(entrance: number) {
    state.entrance = entrance
    state.searchCitys = []
    state.leftPx = state.entrance === 1 ? '30px' : '248px'

    state.isPopCity = true
    state.isSearchCity = false

    // 热门城市已选中元素样式更改
    const { flightStartCity = {}, flightEndCity = {} } = pstate
    const cityList = state.flightHotCitys.list
    cityList.forEach((n, m) => {
      n.cityList.forEach((item, index) => {
        item.current = false
        if ((state.entrance === 1 && flightStartCity.CityName === item.CityName && flightStartCity.CityCode === item.CityCode)
          || (state.entrance === 2 && flightEndCity.CityName === item.CityName && flightEndCity.CityCode === item.CityCode)) {
          item.current = true
        }
      })
    })
    state.flightHotCitys.list = cityList
  },
  close() {
    state.isPopCity = false
    state.isSearchCity = false
  },
  // 热门城市选择绑定点击事件
  bindHotCityEvent(item: object, name: string) {
    const cityItem = state.flightHotCitys.list
    cityItem.forEach((n, m) => {
      n.cityList.forEach((itm, inx) => {
        itm.current = false
      })
    })
    item.current = true
    state.flightHotCitys.list = cityItem
    this.selectCity(item, name)
  },
  // 选择城市
  selectCity(city: object, name = '') {
    if (city) {
      if (city && city.clickable === '0') { return } // 这里要注意，不可点
      if (state.entrance === 1) {
        pmethods.setFlightStartCity(city, true)
      }
      else {
        pmethods.setFlightEndCity(city)
      }

      $utils.sendEvent('', '', '机票主搜', `^${state.entrance === 1 ? '出发城市' : '到达城市'}^模块:${name || '直接输入'}^城市名称:${city.CityName}^城市三字码:${city.CityCode}^`)
      state.searchCitys = []
      pmethods.cityOnBlur()
      pmethods.setData({
        isMask: false,
      })
    }
  },
  async searchSubmit(val: string) {
    state.isPopCity = false
    state.isSearchCity = true
    state.keywordTokens = ''
    state.searchTxt = val

    const obj = {
      url: '/pcapi/flight/search', // 请求api接口地址
      method: 'GET',
      query: {
        keyWord: val,
      },
    }

    const data = await http(obj)
    let list = []

    // if(entrance != state.entrance) return

    if (data && data.data && data.data.rl && data.data.rl.length) {
      if (data.data && data.data.keywordTokens) {
        state.keywordTokens = data.data.keywordTokens
      }
      data.data.rl.forEach((n) => {
        const c = {
          type: n.isInter === '0' ? 1 : 0,
          CityCode: n.code,
          CityName: n.cityName,
          PortCode: n.airPortCode || '',
          PortName: (n.nameType === '2' || n.nameType === '3') ? n.name : '',
          showName: n.showName,
          parentName: n.parentName,
          list: [],
          clickable: n.clickable,
          hasAirPort: n.hasAirPort || '0',
          nameType: n.nameType, // by dll12714,2018/06/13 用来显示搜索结果显示哪个类型的icon
          acClickEvent: n.acClickEvent,
        }
        c.CityNameEn = (n.nameType === '0' || n.nameType === '1') ? n.enname : ''
        c.PortNameEn = (n.nameType === '2' || n.nameType === '3') ? n.enname : ''

        n && n.list && n.list.length && n.list.forEach((m, mx) => {
          let distanceStr = 0
          if (m.distance) {
            distanceStr = m.distance
            distanceStr = distanceStr.replace(/[^0-9]/ig, '')
          }
          if (mx === n.list.length - 1) {
            m.last = true
          }
          const a = {
            type: c.type,
            CityCode: m.code,
            CityName: m.cityName,
            PortCode: m.airPortCode || '',
            PortName: m.airPortShortName || '',
            showName: m.showName,
            distance: `${distanceStr}km`,
            near: m.near, // by dll12714,2018/06/13 用来显示搜索该城市是否有邻近机场标识
            nameType: m.nameType, // by dll12714,2018/06/13 用来显示搜索结果显示哪个类型的icon
            acClickEvent: m.acClickEvent,
            last: m.last,
          }
          // 机场
          // if (m.nameType === '2' || m.nameType === '3') {
          // }
          c.list.push(a)
        })
        list.push(c)
      })
      // window.scrollTo(0, 0)
    }
    else {
      list = [{
        error: 1,
      }]
    }

    state.searchCitys = list
  },
  // 替换搜索的信息 标绿
  replaceSeach(txt: string): string {
    if (!txt) { return '' }
    let reg: any = '';
    (state.keywordTokens || state.searchTxt || '').split(' ').forEach((n) => {
      reg += `(${n})?`
    })
    try {
      reg = new RegExp(reg, 'gi')
    }
    catch (e) {}
    txt = txt.replace(reg, (n) => {
      return n ? `<span class='highlight'>${n}</span>` : ''
    })
    txt = txt.replace(/(无机场)/, '<span style=\'color:#999;\'>$1</span>')
    return txt
  },
  mouseover(i: object) {
    if (i.clickable === '0') { return } // 这里要注意，不可点
    if (i.nameType === '4' || i.nameType === '5') { return }
    state.searchCitys.forEach((n, m) => {
      n.isHover = false
      n.list && n.list.forEach((item, index) => {
        item.isHover = false
      })
    })
    i.isHover = true
  },
  searchCityClick(item: object, dataMain: number, e) {
    let dataCity = item // 城市数据
    if (dataMain && dataMain === 1) {
      const dataClName = item.CityName; const dataClCode = item.CityCode
      for (let n = 0; n < state.searchCitys.length; n++) {
        if (state.searchCitys[n].CityName === dataClName && state.searchCitys[n].CityCode === dataClCode) {
          state.searchCitys[n].CityNameEn = state.searchCitys[n].CityName
          dataCity = state.searchCitys[n]
          break
        }
      }
    }
    this.selectCity(dataCity)
  },

}

watch(
  () => state.entrance, (newValue, oldValue) => {
    pmethods.setData({
      entrance: newValue,
    })
  },
)

onMounted(() => {
  methods.init()
})

defineExpose({
  state,
  open: methods.open,
  close: methods.close,
  searchSubmit: methods.searchSubmit,
})
</script>

<template>
  <div>
    <!-- 热门城市 -->
    <div v-show="state.isPopCity" class="popular-city-wrapper box-dow clearfix" :style="{ left: state.leftPx }">
      <ul>
        <li v-for="(n) in state.flightHotCitys.list" :key="n">
          <p class="area-title">
            {{ n.areaTitle }}
          </p>
          <div class="mNotice-city clearfix">
            <span v-for="(item) in n.cityList" :key="item" class="mNotice-normal" @click="methods.bindHotCityEvent(item, n.areaTitle)">
              <em class="city" :class="item.current ? 'current' : ''" data-city="" data-type="3" data-area="熱門城市">{{ item.CityNameX }}</em>
            </span>
          </div>
        </li>
      </ul>
    </div>

    <!-- 城市搜索框 -->
    <div v-show="state.isSearchCity && state.searchCitys.length > 0" class="search-city-wrapper box-dow" :style="{ left: state.leftPx }">
      <div class="search-list">
        <template v-for="(n) in state.searchCitys" :key="n">
          <p v-if="n.error" class="no_city">
            {{ utils.tc('b_iflight_noresults') }}
          </p>
          <p
            v-else
            class="search_city text-esllip search_click"
            :class="n.nameType=='0'||n.nameType=='1'?'icon_city':
                     n.nameType=='2'||n.nameType=='3'?'icon_air':
                     n.nameType=='4'||n.nameType=='7'?'icon_sheng':
                     n.nameType=='5'?'icon_country':'', n.isHover?'list_hover':''"
            @mouseover="methods.mouseover(n)"
            @click="methods.searchCityClick(n, 1)"
          >
            <span v-if="(state.curLang === 'zh-tw' || state.curLang === 'zh-hk') && (n.nameType === '0' || n.nameType === '1')" v-html="methods.replaceSeach(`${n.showName} ${n.parentName}`)" />
            <span v-else v-html="methods.replaceSeach(n.showName)" />
          </p>

          <template v-for="(item) in n.list" :key="item">
            <div v-if="item.near === '1'" class="search_near clearfix search_click" :class="item.isHover ? 'list_hover' : '', item.last?'btm_10':'btm_1'" @mouseover="methods.mouseover(item)" @click="methods.searchCityClick(item)">
              <span class="near1" />
              <p class="near2">
                <span class="showtxt" v-html="methods.replaceSeach(item.showName)" />
                <span class="distance">{{ item.distance }}</span>
              </p>
            </div>
            <p v-else class="search_air search_click" :class="item.isHover ? 'list_hover' : '', item.last?'btm_10':'btm_1'" @mouseover="methods.mouseover(item)" @click="methods.searchCityClick(item)" v-html="methods.replaceSeach(item.showName)" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-city-wrapper .no_city {
  padding-left: 20px;
  line-height: 16px;
  font-size: 16px;
  color: #666
}

.popular-city-wrapper {
  z-index: 99;
  position: absolute;
  // top: 74px;
  top: 128px;
  width: 580px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 14px
}

.popular-city-wrapper ul>li>div {
  padding: 0 10px
}

.popular-city-wrapper .area-title {
  margin-bottom: 18px;
  padding: 0 20px;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  font-weight: 700;
  color: #333;
  background: #f4f6f9
}

.popular-city-wrapper ul>li:first-child .area-title {
  border-radius: 6px 6px 0 0
}

.popular-city-wrapper .mNotice-normal {
  float: left;
  cursor: pointer;
  display: block;
  height: 26px;
  line-height: 26px;
  padding: 0 0 12px 0;
  margin: -3px 20px 0 0;
  width: 120px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: content-box;
}

.popular-city-wrapper .mNotice-normal em {
  display: inline-block;
  padding: 0 10px;
  height: 26px;
  line-height: 26px;
  max-width: 100%;
  font-style: normal;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.popular-city-wrapper .mNotice-normal em:hover {
  color: var(--Brand-Green)
}

.popular-city-wrapper em.current {
  font-weight: 500;
  color: var(--Brand-Green);
  box-sizing: border-box
}

.seat-passenger-wrapper {
  display: none;
  z-index: 99;
  position: absolute;
  top: 60px;
  left: 0;
  width: 320px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-top: 4px;
  background-color: #fff
}

.seat-wrapper {
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px
}

.seat-wrapper .round-li {
  padding: 0 20px;
  min-height: auto;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  color: #666;
  cursor: pointer
}

.seat-wrapper .round-li:hover {
  color: var(--Brand-Green)
}

.seat-wrapper .round-li.selected {
  color: var(--Brand-Green);
  font-weight: 500;
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmIE1qNJm.png) no-repeat 93% center;
  -webkit-background-size: 17px 14px;
  background-size: 17px 14px
}

.seat-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 16px;
  background-color: #f4f6f9
}

.seat-wrapper::-webkit-scrollbar-thumb {
  width: 8px;
  height: 80px;
  background: #ccc;
  border-radius: 5px
}

.search-city-wrapper {
  z-index: 100;
  position: absolute;
  top: 126px;
  padding: 20px 0;
  width: 420px;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px
}

.search-city-wrapper .search_click.list_hover {
  color: var(--Brand-Green);
  cursor: pointer
}

.search-city-wrapper .search_city {
  position: relative;
  margin-bottom: 11px;
  padding: 0 30px 0 44px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  line-height: 30px
}

.search-city-wrapper .search_city:before {
  content: '';
  position: absolute;
  left: 20px;
  width: 14px;
  height: 30px
}

.search-city-wrapper .icon_country::before {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmEwKrQ0E.png) no-repeat center center
}

.search-city-wrapper .icon_sheng::before {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmFj7QaBO.png) no-repeat center center
}

.search-city-wrapper .icon_city::before {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmHBP2LBe.png) no-repeat center center
}

.icon_air::before {
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_air_01.png) no-repeat center center
}

.search-city-wrapper .search_country {
  margin-left: 20px;
  line-height: 30px;
  font-size: 12px;
  color: #999
}

.search-city-wrapper .search_air {
  position: relative;
  line-height: 30px;
  padding-left: 62px;
  padding-right: 20px;
  font-size: 14px;
  color: #666
}

.search-city-wrapper .search_air:before {
  content: '';
  position: absolute;
  left: 40px;
  width: 12px;
  height: 30px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_city_zhe.png) no-repeat center center;
  background-size: 12px 7px
}

.search-city-wrapper .search_near {
  padding-left: 44px;
  line-height: 20px;
  margin-bottom: 5px
}

.btm_10 {
  margin-bottom: 15px !important
}

.near1 {
  vertical-align: top;
  display: inline-block;
  margin-top: 7px;
  width: 29px;
  height: 14px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/label_search_near.png) no-repeat center;
  background-size: 29px 14px
}

.near2 {
  display: inline-block;
  width: 306px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  font-size: 14px;
  color: #666;
  position: relative
}

.near2 .showtxt {
  display: inline-block;
  width: 260px
}

.near2 .distance {
  position: absolute;
  right: 0;
  top: 4px;
  color: #ccc;
  font-size: 12px
}

.search-city-wrapper .no_city {
  padding-left: 20px;
  line-height: 16px;
  font-size: 16px;
  color: #666
}

.box-dow {
  box-shadow: 0 0 10px 1px rgba(84,95,141,.2);
}
</style>

<style>
.search-city-wrapper .search_city .highlight {
  color: var(--Brand-Green)
}
</style>
